import { Box, Typography } from "@mui/material";
import React from "react";
import imgFeatureOne from "../images/feature-1.svg";
import imgFeatureTwo from "../images/feature-2.svg";
import imgFeatureThree from "../images/feature-3.svg";
import imgFeatureFour from "../images/feature-4.svg";
import imgFeatureFive from "../images/feature-5.svg";
import imgFeatureSix from "../images/feature-6.svg";
import imgIzabelle from "../images/izabelle.webp";
const features = [
  {
    img: imgFeatureOne,
    copy: "Mines your database to reactivate forgotten patients",
  },
  {
    img: imgFeatureTwo,
    copy: '"Set it and forget it" campaign setup with 1-click',
  },
  {
    img: imgFeatureThree,
    copy: "Templates proven to deliver outcomes",
  },
  {
    img: imgFeatureFour,
    copy: "Transparent analytics that surface key metrics",
  },
  {
    img: imgFeatureFive,
    copy: "Intuitive dashboards that are easy to read",
  },
  {
    img: imgFeatureSix,
    copy: "Seamless integration with your EHR",
  },
];

const WhatClientsLove = ({ showTestimonia = true }) => {
  return (
    <Box sx={{ backgroundColor: "#fff", borderRadius: 3, mb: 4 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          pt: 5,
          pb: 4,
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h2"
          component="p"
          sx={{
            mb: 0,
            color: "#270E67",
            fontSize: { xs: "28px", md: "40px" },
            textAlign: "center",
          }}
        >
          What clients love about Bonsai
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          pb: 5,
        }}
      >
        {features.map((feature, i) => (
          <Box className="feature-block" key={i}>
            <Typography component="p">{feature.copy}</Typography>
            <Box
              component="img"
              src={feature.img}
              alt="feature image"
              width="50"
              height="50"
              sx={{ width: 60 }}
            />
          </Box>
        ))}
      </Box>

      {showTestimonia && (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            position: "relative",
            alignItems: { xs: "center" },
          }}
        >
          <Box
            sx={{
              textAlign: { xs: "center", lg: "left" },
              py: { xs: 5, lg: 10 },
              px: { xs: 2, md: 10 },
              maxWidth: { xs: "100%", lg: 920 },
            }}
          >
            <Typography
              variant="h1"
              component="p"
              sx={{
                color: "#2c0e67",
                fontFamily: "IvarDisplay-Regular",
                mb: 3,
                fontWeight: 400,
                fontSize: { xs: "32px", md: "40px" },
                lineHeight: 1.25,
              }}
            >
              &#8220;Our practice generated more than 40 appointments through
              our existing patient base within the first month of launching
              Bonsai, driving approximately{" "}
              <Typography
                component="span"
                sx={{
                  color: "#7E58FA",
                  fontFamily: "IvarDisplay-Regular",
                  fontSize: { xs: "32px", md: "40px" },
                  fontWeight: 500,
                }}
              >
                $51K in revenue
              </Typography>
              .&#8221;
            </Typography>
            <Typography
              component="p"
              sx={{ color: "#2c0e67", fontWeight: 700, fontSize: "20px" }}
            >
              Izabelle, Practice Manager
            </Typography>
            <Typography
              component="p"
              sx={{ color: "#2c0e67", fontSize: "20px" }}
            >
              Moksha Aesthetics
            </Typography>
          </Box>
          <Box
            component="img"
            src={imgIzabelle}
            alt="botox"
            sx={{
              width: { xs: 200, md: 380 },
              height: { xs: 211, md: 408 },
              position: { xs: "relative", lg: "absolute" },
              right: { xs: 0, lg: 100 },
              bottom: 0,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default WhatClientsLove;
