import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import ContactForm from "./ContactForm";

const DemoHero = ({
  heroImg = null,
  partner = null,
  heading = "Request a free demo",
  detail = "Please provide the following, and we’ll get back to you shortly with a personalized demo.",
  descriptionMaxWidth = "100%",
  heroHeight = 710,
}) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        height: { xs: "auto", lg: heroHeight, position: "relative" },
        mb: { xs: 4, lg: 0 },
      }}
    >
      {heroImg && (
        <Box
          component="img"
          src={heroImg}
          alt="botox"
          sx={{
            width: 1166,
            height: "auto",
            bottom: 0,
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            display: { xs: "none", lg: "block" },
          }}
        />
      )}

      <Grid item xs={12} md={7}>
        <Box sx={{ width: "100%", maxWidth: { xs: "100%", lg: 473 } }}>
          <Typography
            component="p"
            sx={{
              fontFamily: "IvarDisplay-Regular",
              fontSize: { xs: "3rem", md: "72px" },
              lineHeight: 1,
              mb: { xs: 2, lg: 5 },
              color: "#270E67",
            }}
          >
            Discover hidden revenue from your data.
          </Typography>
          <Typography
            component="p"
            sx={{
              color: "#685695",
              fontSize: "18px",
              mb: 2,
              maxWidth: { xs: "100%", lg: descriptionMaxWidth },
            }}
          >
            See how Bonsai can discover hidden revenue from your data and boost
            patient retention. Tell us a bit about your business, and we'll
            connect you with a Bonsai Health expert. They'll provide an in-depth
            product demo and answer any questions you may have.
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Box>
          <ContactForm partner={partner} heading={heading} detail={detail} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default DemoHero;
