import { Box, Typography } from "@mui/material";
import React from "react";

const steps = [
  "Our HIPAA-compliant platform connects data from your EHR, surfacing hidden revenue opportunities across services.",
  "View patients with overdue treatments and the associated revenue potential within Bonsai's visual dashboard.",
  "Send 1-click campaigns targeting your top-performing services and drive repeat visits. Track all the key results of your campaigns.",
];

const HowItWorks = () => {
  return (
    <Box sx={{ backgroundColor: "#fff", borderRadius: 3, pb: 4, px: 2 }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          pt: 5,
          pb: 4,
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h2"
          component="p"
          sx={{
            mb: 2,
            color: "#270E67",
            fontSize: { xs: "28px", md: "40px" },
            textAlign: "center",
          }}
        >
          How it works
        </Typography>
        <Typography
          component="p"
          sx={{
            mb: 2,
            maxWidth: 700,
            textAlign: "center",
            fontSize: "22px",
            lineHeight: "28px",
            color: "#270E67",
          }}
        >
          Numbers like words can tell a story. By unifying your data, Bonsai
          surfaces actionable insights to drive high-value opportunies.
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: { xs: "center", md: "normal" },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        {steps.map((step, i) => (
          <Box className="step-block" key={i}>
            <Typography className="step-number">Step {i + 1}</Typography>
            <Typography component="p">{step}</Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default HowItWorks;
