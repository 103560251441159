import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import TopNav from "../components/TopNav";

const BusinessAssociateAgreement = () => {
  return (
    <>
      <Helmet>
        <title>Business Associate Agreement | Bonsai</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <TopNav />
      <Container sx={{ p: 3 }}>
        <Box pt={3}>
          <Typography
            component="p"
            variant="bodyArial"
            sx={{ mb: 2, textAlign: "center" }}
          >
            <strong>B</strong>usiness <strong>A</strong>ssociate{" "}
            <strong>A</strong>greement
          </Typography>
          <Typography component="p" variant="bodyArial" sx={{ mb: 2 }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; This Business Associate
            Agreement (this “
            <strong>
              <i>BAA</i>
            </strong>
            ”) by and between Bonsai Health, Inc. (“
            <strong>
              <i>Business Associate</i>
            </strong>
            ”) and the legal entity named as the user for Business Associate’s
            services (“
            <strong>
              <i>Covered Entity</i>
            </strong>
            ”), is effective as of the Covered Entity’s acceptance of this BAA
            (“
            <strong>
              <i>Effective Date</i>
            </strong>
            ”) for the purposes of complying with the Health Insurance
            Portability and Accountability Act of 1996 and regulations
            promulgated thereunder (“
            <strong>
              <i>HIPAA</i>
            </strong>
            ”) and the security provisions of the American Recovery and
            Reinvestment Act of 2009, also known as the Health Information
            Technology for Economic and Clinical Health Act (the “
            <strong>
              <i>HITECH Act</i>
            </strong>
            ”). Upon the Effective Date of this BAA, this BAA shall be
            incorporated by reference and be made part of the Terms of Use
            Agreement (“
            <strong>
              <i>Service Agreement</i>
            </strong>
            ”). The parties acknowledge and agree that (1) the obligations set
            forth in this BAA apply if and solely to the extent that Business
            Associate receives, creates, maintains, uses, or discloses Protected
            Health Information in connection with the services that Business
            Associate performs on behalf of Covered Entity; and (2) this BAA (to
            the extent that it applies), along with the Service Agreement,
            govern Covered Entity’s use of the Business Associate services.
          </Typography>
          <Typography component="p" variant="bodyArial" sx={{ mb: 2 }}>
            PLEASE READ THIS BAA CAREFULLY. BY CLICKING ON THE “I ACCEPT”
            BUTTON, THE INDIVIDUAL ACCESSING THE BUSINESS ASSOCIATE’S SERVICES
            REPRESENTS THAT (1) SUCH INDIVIDUAL HAS READ, UNDERSTANDS, AND
            AGREES TO BE BOUND BY THIS BAA, (2) SUCH INDIVIDUAL IS OF LEGAL AGE
            TO FORM A BINDING CONTRACT WITH THE BUSINESS ASSOCIATE, AND (3) SUCH
            INDIVIDUAL HAS THE AUTHORITY TO ENTER INTO THE BAA ON BEHALF OF
            COVERED ENTITY AND TO BIND COVERED ENTITY TO THE BAA.{" "}
            <strong>
              IF COVERED ENTITY DOES NOT AGREE TO BE BOUND BY THIS BAA, COVERED
              ENTITY MAY NOT USE THE SERVICES TO TRANSMIT, STORE, PROCESS, OR
              EXCHANGE PROTECTED HEALTH INFORMATION OR OTHERWISE INCLUDE ANY
              PROTECTED HEALTH INFORMATION IN YOUR DATA.
            </strong>
          </Typography>
          <Typography
            component="p"
            variant="bodyArial"
            sx={{ mb: 2, textAlign: "center" }}
          >
            <strong>WITNESSETH</strong>
          </Typography>
          <Typography
            component="ul"
            className="emptyDecimal"
            variant="bodyTimes"
            sx={{ mb: 2 }}
          >
            <li>
              <span>&nbsp;</span>
              <strong>W</strong>hereas, Covered Entity is a covered entity as
              such term is defined under HIPAA and as such is required to comply
              with the requirements thereof regarding the confidentiality and
              privacy of Protected Health Information; and
            </li>
            <li>
              <span>&nbsp;</span>
              <strong>W</strong>hereas, Covered Entity and Business Associate
              have entered into the Service Agreement pursuant to which Business
              Associate may receive Protected Health Information for or on
              behalf of Covered Entity; and
            </li>
            <li>
              <span>&nbsp;</span>
              <strong>W</strong>hereas, by providing services pursuant to the
              Service Agreement and receiving Protected Health Information for
              or on behalf of Covered Entity, Business Associate shall become a
              Business Associate of Covered Entity, as such term is defined
              under HIPAA, and will therefore have obligations regarding the
              confidentiality and privacy of Protected Health Information that
              Business Associate receives from or on behalf of, Covered Entity.
            </li>
            <li>
              <span>&nbsp;</span>
              <strong>N</strong>ow <strong>T</strong>herefore, in consideration
              of the mutual covenants, promises, and agreements contained
              herein, the parties hereto agree as follows:
            </li>
            <li>
              <span>&nbsp;</span>
              <span>1.</span>
              <strong>D</strong>efinitions. For the purposes of this BAA,
              capitalized terms shall have the meanings ascribed to them below.
              All capitalized terms used but not otherwise defined herein will
              have the meaning ascribed to them by HIPAA or the Service
              Agreement.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(a)</span>“
              <strong>
                <i>Protected Health Information</i>
              </strong>
              ” or “
              <strong>
                <i>PHI</i>
              </strong>
              ” is any information, whether oral or recorded in any form or
              medium that is created, received, maintained, or transmitted by
              Business Associate for or on behalf of Covered Entity, that
              identifies an individual or might reasonably be used to identify
              an individual and relates to: (i) the individual’s past, present
              or future physical or mental health; (ii) the provision of health
              care to the individual; or (iii) the past, present or future
              payment for health care.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(b)</span>“
              <strong>
                <i>Secretary</i>
              </strong>
              ” shall refer to the Secretary of the U.S. Department of Health
              and Human Services.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(c)</span>“
              <strong>
                <i>Unsecured PHI</i>
              </strong>
              ” shall mean PHI that is not rendered unusable, unreadable, or
              indecipherable to unauthorized individuals through the use of a
              technology or methodology specified by the Secretary (e.g.,
              encryption). This definition applies to both hard copy PHI and
              electronic PHI.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>2.</span>
              <strong>O</strong>bligations of <strong>B</strong>usiness{" "}
              <strong>A</strong>ssociate.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(a)</span>
              <strong>General Compliance with Law</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              Business Associate warrants that it, its agents and its
              subcontractors: (i) shall use or disclose PHI only in connection
              with fulfilling its duties and obligations under this BAA and the
              Service Agreement; (ii) shall not use or disclose PHI other than
              as permitted or required by this BAA or required by law; (iii)
              shall not use or disclose PHI in any manner that violates
              applicable federal and state laws or would violate such laws if
              used or disclosed in such manner by Covered Entity; and (iv) shall
              only use and disclose the minimum necessary PHI for its specific
              purposes.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(b)</span>
              <strong>
                Use and Disclosure of Protected Health Information
              </strong>
            </li>
            <li>
              <span>&nbsp;</span>
              Subject to the restrictions set forth throughout this BAA,
              Business Associate may use the information received from Covered
              Entity if necessary for (i) the proper management and
              administration of Business Associate; or (ii) to carry out the
              legal responsibilities of Business Associate.
            </li>
            <li>
              <span>&nbsp;</span>
              Subject to the restrictions set forth in throughout this BAA,
              Business Associate may disclose PHI for the proper management and
              administration of Business Associate, provided that: (i)
              disclosures are required by law, or (ii) Business Associate
              obtains reasonable assurances from the person or entity to whom
              the information is disclosed that it will remain confidential and
              used or further disclosed only as Required by Law or for the
              purpose for which it was disclosed to the person or entity, and
              the person or entity notifies the Business Associate of any
              instances of which it is aware in which the confidentiality of the
              information has been breached.
            </li>
            <li>
              <span>&nbsp;</span>
              Business Associate is permitted, for Data Aggregation purposes to
              the extent permitted under HIPAA, to use, disclose, and combine
              PHI created or received on behalf of Covered Entity by Business
              Associate pursuant to this BAA with PHI, as defined by 45 C.F.R.
              160.103, received by Business Associate in its capacity as a
              business associate of other covered entities, to permit data
              analyses that relate to the Health Care Operations of the
              respective covered entities and/or Covered Entity.
            </li>
            <li>
              <span>&nbsp;</span>
              Business Associate may de-identify any and all PHI created or
              received by Business Associate under this BAA. Once PHI has been
              de-identified pursuant to 45 CFR 164.514(b), such information is
              no longer Protected Health Information and no longer subject to
              this BAA.
            </li>
            <li>
              <span>&nbsp;</span>
              Business Associate acknowledges that, as between Business
              Associate and Covered Entity, all PHI shall be and remain the sole
              property of Covered Entity, including any and all forms thereof
              developed by Business Associate in the course of its fulfillment
              of its obligations pursuant to the BAA and Service Agreement.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(c)</span>
              <strong>Covered Entity Obligations</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              To the extent that Business Associate is to carry out any of
              Covered Entity’s obligations that are regulated by HIPAA, Business
              Associate shall comply with the HIPAA requirements that apply to
              the Covered Entity in the performance of such obligation.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(d)</span>
              <strong>Safeguards</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              Business Associate shall employ appropriate administrative,
              technical and physical safeguards, consistent with the size and
              complexity of Business Associate’s operations, to protect the
              confidentiality of PHI and to prevent the use or disclosure of PHI
              in any manner inconsistent with the terms of this BAA. Business
              Associate shall comply, where applicable, with Subpart C of 45
              C.F.R. Part 164 with respect to electronic PHI to prevent use or
              disclosure of such electronic PHI other than as provided for by
              this BAA.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(e)</span>
              <strong>Availability of Books and Records</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              Business Associate shall permit the Secretary and other regulatory
              and accreditation authorities to audit Business Associate’s
              internal practices, books and records at reasonable times as they
              pertain to the use and disclosure of PHI in order to ensure that
              Covered Entity and/or Business Associate is in compliance with the
              requirements of HIPAA.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(f)</span>
              <strong>Individuals’ Rights to Their PHI</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(i)</span>
              <strong>Access to Information</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              To the extent Business Associate maintains PHI in a Designated
              Record Set, in order to allow Covered Entity to respond to a
              request by an Individual for access to PHI pursuant to 45 CFR
              Section 164.524, Business Associate, within ten (10) business days
              upon receipt of written request by Covered Entity, shall make
              available to Covered Entity such PHI. In the event that any
              Individual requests access to PHI directly from Business
              Associate, Business Associate shall forward such request to
              Covered Entity within five (5) business days.
            </li>
            <li>
              <span>&nbsp;</span>
              Covered Entity will be responsible for making all determinations
              regarding the grant or denial of an Individual’s request for PHI
              and Business Associate will make no such determinations. Except as
              Required by Law, only Covered Entity will be responsible for
              releasing PHI to an Individual pursuant to such a request. Any
              denial of access to PHI determined by Covered Entity pursuant to
              45 CFR Section 164.524, and conveyed to Business Associate by
              Covered Entity, shall be the responsibility of Covered Entity,
              including resolution or reporting of all appeals and/or complaints
              arising from denials.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(ii)</span>
              <strong>Amendment of Information</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              To the extent Business Associate maintains PHI in a Designated
              Record Set, in order to allow Covered Entity to respond to a
              request by an Individual for an amendment to PHI, Business
              Associate shall, within ten (10) business days upon receipt of a
              written request by Covered Entity, make available to Covered
              Entity such PHI. In the event that any Individual requests
              amendment of PHI directly from Business Associate, Business
              Associate shall forward such request to Covered Entity within five
              (5) business days.
            </li>
            <li>
              <span>&nbsp;</span>
              Covered Entity will be responsible for making all determinations
              regarding the grant or denial of an Individual’s request for an
              amendment to PHI and Business Associate will make no such
              determinations. Any denial of amendment to PHI determined by
              Covered Entity pursuant to 45 CFR Section 164.526, and conveyed to
              Business Associate by Covered Entity, shall be the responsibility
              of Covered Entity, including resolution or reporting of all
              appeals and/or complaints arising from denials.
            </li>
            <li>
              <span>&nbsp;</span>
              Within ten (10) business days of receipt of a request from Covered
              Entity to amend an individual’s PHI in the Designated Record Set,
              Business Associate shall incorporate any approved amendments,
              statements of disagreement, and/or rebuttals into its Designated
              Record Set as required by 45 CFR Section 164.526.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(iii)</span>
              <strong>Accounting of Disclosures</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              In order to allow Covered Entity to respond to a request by an
              Individual for an accounting pursuant to 45 CFR Section 164.528,
              Business Associate shall, within ten (10) business days of a
              written request by Covered Entity for an accounting of disclosures
              of PHI about an Individual, make available to Covered Entity such
              PHI. At a minimum, Business Associate shall provide Covered Entity
              with the following information: (a) the date of the disclosure;
              (b) the name of the entity or person who received the PHI, and if
              known, the address of such entity or person; (c) a brief
              description of the PHI disclosed; and (d) a brief statement of the
              purpose of such disclosure. In the event that any Individual
              requests an accounting of disclosures of PHI directly from
              Business Associate, Business Associate shall forward such request
              to Covered Entity within five (5) business days. Covered Entity
              will be responsible for preparing and delivering an accounting to
              Individual. Business Associate shall implement an appropriate
              record keeping process to enable it to comply with the
              requirements of this BAA.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(g)</span>
              <strong>Disclosure to Subcontractors and Agents</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              Notwithstanding anything to the contrary in the Services Agreement
              or this BAA, Business Associate, subject to the restrictions set
              forth in this provision, may use subcontractors to fulfill its
              obligations under this BAA. Business Associate shall obtain and
              maintain a written agreement with each subcontractor or agent that
              has or will have access to PHI, which is received from, or created
              or received by, Business Associate for or on behalf of Covered
              Entity, pursuant to which such subcontractor and agent agrees to
              be bound by the same restrictions, terms, and conditions that
              apply to Business Associate under this BAA with respect to such
              PHI.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(h)</span>
              <strong>Reporting Obligations</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              In the event of a Breach of any Unsecured PHI that Business
              Associate accesses, maintains, retains, modifies, records, or
              otherwise holds or uses on behalf of Covered Entity, Business
              Associate shall report such Breach to Covered Entity as soon as
              practicable, but in no event later than ten (10) business days
              after the date the Breach is discovered. Notice of a Breach shall
              include, to the extent such information is available: (i) the
              identification of each individual whose PHI has been, or is
              reasonably believed to have been, accessed, acquired, or disclosed
              during the Breach; (ii) the date of the Breach, if known, and the
              date of discovery of the Breach; (iii) the scope of the Breach;
              and (iv) the Business Associate’s response to the Breach.
            </li>
            <li>
              <span>&nbsp;</span>
              In the event of a use or disclosure of PHI that is improper under
              this BAA but does not constitute a Breach, Business Associate
              shall report such use or disclosure to Covered Entity within ten
              (10) business days after the date on which Business Associate
              becomes aware of such use or disclosure.
            </li>
            <li>
              <span>&nbsp;</span>
              In the event of any successful Security Incident, Business
              Associate shall report such Security Incident in writing to
              Covered Entity within ten (10) business days of the date on which
              Business Associate becomes aware of such Security Incident. The
              parties acknowledge that unsuccessful Security Incidents that
              occur within the normal course of business shall not be reported
              pursuant to this BAA. Such unsuccessful Security Incidents
              include, but are not limited to, port scans or “pings,” and
              unsuccessful log-on attempts, broadcast attacks on Business
              Associate’s firewall, denials of service or any combination
              thereof if such incidents are detected and neutralized by Business
              Associate’s anti-virus and other defensive software and not
              allowed past Business Associate’s firewall.
            </li>
            <li>
              <span>&nbsp;</span>
              Business Associate will identify and respond internally to any
              suspected or known Breach of any Unsecured PHI, Security Incident
              or other improper use or disclosure of PHI, and will mitigate, to
              the extent practicable, their harmful effects, document their
              outcomes, and provide documentation of any successful Security
              Incident and Breach of any Unsecured PHI to Covered Entity upon
              request.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>3.</span>
              <strong>O</strong>bligations <strong>O</strong>f{" "}
              <strong>C</strong>overed <strong>E</strong>ntity.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(a)</span>
              <strong>
                Inform Business Associate of Protected Health Information
              </strong>
            </li>
            <li>
              <span>&nbsp;</span>
              Covered Entity shall inform Business Associate in writing that its
              Data contains protected health information in accordance with
              Section 5.5 of the Service Agreement.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(b)</span>
              <strong>Permissible Requests</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              Covered Entity shall not request Business Associate to use or
              disclose PHI in any manner that would violate applicable federal
              and state laws if such use or disclosure were made by Covered
              Entity. Covered Entity may request Business Associate to disclose
              PHI directly to another party only for the purposes allowed by
              HIPAA and the HITECH Act.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(c)</span>
              <strong>Notifications</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              Covered Entity shall notify Business Associate of any limitation
              in any applicable notice of privacy practices in accordance with
              45 CFR Section 164.520, to the extent that such limitation may
              affect Business Associate’s use or disclosure of PHI.
            </li>
            <li>
              <span>&nbsp;</span>
              Covered Entity shall notify Business Associate of any changes in,
              or revocation of, permission by individual to use or disclose PHI,
              to the extent that such changes may affect Business Associate’s
              use or disclosure of PHI.
            </li>
            <li>
              <span>&nbsp;</span>
              Covered Entity shall notify Business Associate of any restriction
              to the use or disclosure of PHI that Covered Entity has agreed to
              in accordance with 45 CFR Section 164.522, to the extent that such
              restriction may affect Business Associate’s use or disclosure of
              PHI.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>4.</span>
              <strong>T</strong>erm and <strong>T</strong>ermination.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(a)</span>
              <strong>General Term and Termination</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              This BAA shall become effective on the Effective Date set forth
              above and shall terminate upon the termination or expiration of
              the Service Agreement and when all PHI provided by either party to
              the other, or created or received by Business Associate on behalf
              of Covered Entity is, in accordance with this Section, destroyed,
              returned to Covered Entity, or protections are extended.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(b)</span>
              <strong>Material Breach</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              Where either party has knowledge of a material breach by the other
              party, the non-breaching party shall provide the breaching party
              with an opportunity to cure. Where said breach is not cured to the
              reasonable satisfaction of the non-breaching party within twenty
              (20) business days of the breaching party’s receipt of notice from
              the non-breaching party of said breach, the non-breaching party
              shall, if feasible, terminate this BAA and the portion(s) of the
              Service Agreement affected by the breach. Where either party has
              knowledge of a material breach by the other party and cure is not
              possible, the non-breaching party shall, if feasible, terminate
              this BAA and the portion(s) of the Service Agreement affected by
              the breach.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(c)</span>
              <strong>Return or Destruction of PHI</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              Upon termination of this BAA for any reason, Business Associate
              shall: (i) if feasible as determined by Business Associate, return
              or destroy all PHI received from, or created or received by
              Business Associate for or on behalf of Covered Entity that
              Business Associate or any of its subcontractors and agents still
              maintain in any form, and Business Associate shall retain no
              copies of such information; or (ii) if Business Associate
              determines that such return or destruction is not feasible, extend
              the protections of this BAA to such information and limit further
              uses and disclosures to those purposes that make the return or
              destruction of the PHI infeasible, in which case Business
              Associate’s obligations under this Section shall survive the
              termination of this BAA.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>5.</span>
              <strong>M</strong>iscellaneous.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(a)</span>
              <strong>Amendment</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              If any of the regulations promulgated under HIPAA or the HITECH
              Act are amended or interpreted in a manner that renders this BAA
              inconsistent therewith, the parties shall amend this BAA to the
              extent necessary to comply with such amendments or
              interpretations.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(b)</span>
              <strong>Interpretation</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              Any ambiguity in this BAA shall be resolved to permit the parties
              to comply with HIPAA and the HITECH Act.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(c)</span>
              <strong>Conflicting Terms</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              In the event that any terms of this BAA conflict with any terms of
              the Service Agreement, the terms of this BAA shall govern and
              control with respect to the subject matter herein.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(d)</span>
              <strong>Notices</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              All notices required or permitted under this BAA must be delivered
              in accordance with the notice procedures set forth in the Service
              Agreement.
            </li>
            <li>
              <span>&nbsp;</span>
              <span>&nbsp;</span>
              <span>(e)</span>
              <strong>Severability</strong>
            </li>
            <li>
              <span>&nbsp;</span>
              The provisions of this BAA shall be severable, and if any
              provision of this BAA shall be held or declared to be illegal,
              invalid or unenforceable, the remainder of this BAA shall continue
              in full force and effect as though such illegal, invalid or
              unenforceable provision had not been contained herein.
            </li>
          </Typography>
        </Box>
      </Container>
    </>
  );
};

export default BusinessAssociateAgreement;
