import { Box, Container } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import imgBotox from "../images/botox.webp";
import TopNav from "../components/TopNav";
import HowItWorks from "../components/HowItWorks";
import WhatClientsLove from "../components/WhatClientsLove";
import DemoHero from "../components/DemoHero";

const Demo = () => {
  return (
    <>
      <Helmet>
        <title>Demo Request | Bonsai</title>
      </Helmet>
      <TopNav />
      <Box sx={{ backgroundColor: "#F1EEFF", py: { xs: 4, lg: 9 } }}>
        <Container>
          <DemoHero heroImg={imgBotox} />
          <WhatClientsLove />
          <HowItWorks />
        </Container>
      </Box>
    </>
  );
};

export default Demo;
