import { Box, Container } from "@mui/material";
import React from "react";
import imgBrand from "../images/brand.svg";
import imgGetDemo from "../images/get_demo.svg";
import { Link } from "react-router-dom";

const TopNav = ({ showDemoLink = true }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
      }}
    >
      <Container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: 80,
        }}
      >
        <Link to="/" className="nav-logo-link">
          <Box
            component="img"
            src={imgBrand}
            alt="logo"
            sx={{ position: "relative", height: { xs: 28 } }}
          />
        </Link>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Link to="mailto:sales@bonsaihealth.com" className="nav-link">
            sales@bonsaihealth.com
          </Link>
          {showDemoLink && (
            <Link to="/demo">
              <Box
                component="img"
                src={imgGetDemo}
                alt="Get Demo"
                sx={{ height: 40, width: "auto", display: "flex" }}
              />
            </Link>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default TopNav;
