import { Box } from "@mui/material";
import React from "react";
import { motion } from "framer-motion";
import MobileSplash from "../components/MobileSplash";
import DesktopSplash from "../components/DesktopSplash";
import DesktopBanners from "../components/DesktopBanners";
import TopNav from "../components/TopNav";

const HomePage = () => {
  return (
    <>
      <motion.div
        initial={{
          top: "-50px",
          position: "absolute",
          opacity: 0,
          width: "100%",
          zIndex: 10,
        }}
        animate={{ top: 0, opacity: 1 }}
        transition={{ duration: 0.5, ease: "easeInOut", delay: 4 }}
      >
        <TopNav />
      </motion.div>
      <Box
        sx={{
          oveflow: "hidden",
          height: "100%",
          position: "relative",
          maxWidth: 1920,
          minHeight: 900,
          marginX: "auto",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", md: 800 },
            minWidth: { xs: "100%", md: 800 },
            padding: { xs: "46px 0 0 16px", md: "56px 0 0 64px" },
          }}
        >
          <MobileSplash />
          <DesktopSplash />
        </Box>
        <Box sx={{ position: "relative", width: "100%" }}>
          <DesktopBanners />
        </Box>
      </Box>
    </>
  );
};

export default HomePage;
