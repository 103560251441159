import { Box, Typography } from "@mui/material";
import imgDrPotozkin from "../images/dr-potozkin.webp";
import imgDrSadeghi from "../images/dr-sadeghi.webp";
import imgDrBehroozan from "../images/dr-behroozan.webp";
import React from "react";

const testimonials = [
  {
    excerpt: (
      <Typography
        sx={{
          fontFamily: "IvarDisplay-Medium",
          fontSize: { xs: "24px", md: "32px" },
          color: "#270E67",
          fontWeight: 500,
          lineHeight: 1.125,
        }}
      >
        “Our practice is driving roughly{" "}
        <Typography variant="span" sx={{ color: "#6F35F2" }}>
          $30K in additional revenue per month
        </Typography>
        . The service has been exceptional.”
      </Typography>
    ),
    name: "Jerome R. Potozkin, MD",
    title: "Potozkin MD Skincare Center",
    image: imgDrPotozkin,
  },
  {
    excerpt: (
      <Typography
        sx={{
          fontFamily: "IvarDisplay-Medium",
          fontSize: { xs: "24px", md: "32px" },
          color: "#270E67",
          fontWeight: 500,
          lineHeight: 1.125,
        }}
      >
        “Right from the start,{" "}
        <Typography variant="span" sx={{ color: "#6F35F2" }}>
          our patient engagement increased substantially
        </Typography>
        . Our patients have appreciated the outreach and the ROI has been great.
        Highly recommend!”
      </Typography>
    ),
    name: "Parrish Sadeghi, MD",
    title: "Pure Dermatology and Skin Surgery Center",
    image: imgDrSadeghi,
  },
  {
    excerpt: (
      <Typography
        sx={{
          fontFamily: "IvarDisplay-Medium",
          fontSize: { xs: "24px", md: "32px" },
          color: "#270E67",
          fontWeight: 500,
          lineHeight: 1.125,
        }}
      >
        “Within days of launching Bonsai, we were able to rebook high-value
        patients immediately. Our practice generated more than{" "}
        <Typography variant="span" sx={{ color: "#6F35F2" }}>
          30 appointments within the first six weeks
        </Typography>
        .”
      </Typography>
    ),
    name: "Daniel Behroozan, MD, FAAD, FACMS",
    title: "Dermatology Institute of Southern California",
    image: imgDrBehroozan,
  },
];

const DermatologistsTestimonials = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          pt: 5,
          pb: 4,
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h2"
          component="p"
          sx={{
            mb: 0,
            color: "#270E67",
            fontSize: { xs: "28px", md: "40px" },
            textAlign: "center",
          }}
        >
          What Dermatologists Love About Bonsai
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {testimonials.map((testimonial, i) => (
          <Box
            key={i}
            sx={{
              padding: { xs: 4, md: "64px 120px" },
              backgroundColor: "#fff",
              borderRadius: "12px",
              mb: 4,
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              textAlign: { xs: "center", md: "left" },
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            <Box
              sx={{
                width: 208,
                height: 208,
                mb: { xs: 3, md: 0 },
              }}
            >
              <img
                src={testimonial.image}
                alt={testimonial.name}
                width="208"
                height="208"
              />
            </Box>
            <Box sx={{ pl: { xs: 0, md: 9 } }}>
              {testimonial.excerpt}
              <Typography
                component="p"
                sx={{
                  fontSize: { xs: "18px", md: "20px" },
                  fontWeight: 600,
                  pt: 3,
                }}
              >
                {testimonial.name}
              </Typography>
              <Typography
                component="p"
                sx={{ fontSize: { xs: "18px", md: "20px" }, fontWeight: 400 }}
              >
                {testimonial.title}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default DermatologistsTestimonials;
