import { Box, Container } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import imgHero from "../../images/botox-beautiphi.webp";
import imgPartnerLogo from "../../images/partner-beautiphi.svg";
import HowItWorks from "../../components/HowItWorks";
import WhatClientsLove from "../../components/WhatClientsLove";
import DemoHero from "../../components/DemoHero";

const Beautiphi = () => {
  return (
    <>
      <Helmet>
        <title>Demo Request | Bonsai</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "#fff",
        }}
      >
        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: 80,
          }}
        >
          <Box
            component="img"
            src={imgPartnerLogo}
            alt="logo"
            sx={{ position: "relative", height: { xs: 28 } }}
          />
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Link to="mailto:sales@bonsaihealth.com" className="nav-link">
              sales@bonsaihealth.com
            </Link>
          </Box>
        </Container>
      </Box>
      <Box sx={{ backgroundColor: "#F1EEFF", py: { xs: 4, lg: 9 } }}>
        <Container>
          <DemoHero heroImg={imgHero} partner="Beautiphi" />
          <WhatClientsLove showTestimonia={false} />
          <HowItWorks />
        </Container>
      </Box>
    </>
  );
};

export default Beautiphi;
